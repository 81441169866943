import {
  AUTH_TOKEN,
  STORAGE_CLIENT_UID,
  STORAGE_CLIENT_ID,
  STORAGE_ROLE,
  STORAGE_USER,
  STORAGE_GUEST,
  STORAGE_CHAT_ROOM,
} from "config/constants";

export const Auth = {
  isAuthenticated: () => {
    const token = localStorage.getItem(AUTH_TOKEN) || "";
    return token.length;
  },
  getToken: () => {
    return localStorage.getItem(AUTH_TOKEN) || "";
  },
  getClientUid: () => {
    return localStorage.getItem(STORAGE_CLIENT_UID) || "";
  },
  getClientId: () => {
    return localStorage.getItem(STORAGE_CLIENT_ID) || "";
  },
  getChatRoom: () => {
    return localStorage.getItem(STORAGE_CHAT_ROOM) || "";
  },
  getUserId: () => {
    return localStorage.getItem(STORAGE_USER) || "";
  },
  getGuestId: () => {
    return localStorage.getItem(STORAGE_GUEST) || "";
  },
  authenticate: async (token: string, role: string, userId: string) => {
    localStorage.setItem(AUTH_TOKEN, token);
    localStorage.setItem(STORAGE_ROLE, role);
    localStorage.setItem(STORAGE_USER, userId);
    window.location.href = "/";
  },
  setClient: (
    guestId: string,
    clientId: string,
    clientUid: string,
    chatRoom: string
  ) => {
    localStorage.setItem(STORAGE_GUEST, guestId);
    localStorage.setItem(STORAGE_CLIENT_UID, clientUid);
    localStorage.setItem(STORAGE_CLIENT_ID, clientId);
    localStorage.setItem(STORAGE_CHAT_ROOM, chatRoom);
  },
  removeClient: () => {
    localStorage.removeItem(STORAGE_CLIENT_UID);
    localStorage.removeItem(STORAGE_CLIENT_ID);
    localStorage.removeItem(STORAGE_GUEST);
    localStorage.removeItem(STORAGE_CHAT_ROOM);
  },
  clear: () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(STORAGE_ROLE);
    localStorage.removeItem(STORAGE_USER);
    localStorage.removeItem(STORAGE_CLIENT_UID);
    localStorage.removeItem(STORAGE_CLIENT_ID);
    localStorage.removeItem(STORAGE_GUEST);
    localStorage.removeItem(STORAGE_CHAT_ROOM);
  },
};
