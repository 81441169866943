import React, { useState } from "react";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { GQLUser } from "models/user";

interface MyAccountProps {
  data: GQLUser;
  refetch: any;
}

export const MyAccount: React.FC<MyAccountProps> = ({ data }) => {
  const [name, setName] = useState(data.name || "");
  const [email, setEmail] = useState(data.email || "");
  const [username, setUsername] = useState(data.username || "");
  return (
    <>
      <h3>My Account</h3>
      <ul className="form">
        <li className="field">
          <div className="field-text">
            <label>Email</label>
            <InputText
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </div>
        </li>
        <li className="field">
          <div className="field-text">
            <label>Username</label>
            <InputText
              value={username}
              onChange={(e: any) => setUsername(e.target.value)}
            />
          </div>
        </li>
        <li className="field">
          <div className="field-text">
            <label>Name</label>
            <InputText
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </div>
        </li>
        <li className="field actions">
          <Button label="Save" onClick={() => {}} />
        </li>
      </ul>
    </>
  );
};
