import gql from "graphql-tag";

export const ACTIVE_PLACES_QUERY = gql`
  query activePlaces($userId: ID!) {
    guests(where: { user: $userId, active: true }) {
      id
      checkedIn
      chatRoom
      client {
        id
        name
        uid
        setting {
          checkIn
        }
        branding {
          logo {
            url
          }
        }
      }
    }
  }
`;
