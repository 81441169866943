import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import moment from "moment";
import classnames from "classnames";
import { Element, scroller } from "react-scroll";
import { Auth } from "utils/auth";
import { fbDatabase } from "config/firebase";

import "./Chat.scss";
import { Chat } from "utils/chat";

export const ChatPage: React.FC = () => {
  const currentChatRoom = `rooms/${Auth.getChatRoom()}/messages`;
  const guestId = Auth.getGuestId();
  const clientUid = Auth.getClientUid();
  const [newMessage, setNewMessage] = useState("");
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [listMessages, setListMessages] = useState([]);

  const sendMessage = (message: string, type: string) => {
    const timestamp = moment().valueOf();
    const itemMessage = {
      idFrom: guestId,
      idTo: clientUid,
      timestamp: timestamp,
      type,
      content: message.trim(),
    };
    fbDatabase.ref(currentChatRoom).push(itemMessage);
  };

  useEffect(() => {
    if (currentChatRoom) {
      const messagesRef = fbDatabase.ref(currentChatRoom);
      messagesRef.on("value", (snapshot) => {
        let messagesObj = snapshot.val();
        if (messagesObj) {
          let messages = [];
          Object.keys(messagesObj).forEach((key) =>
            messages.push(messagesObj[key])
          );
          setListMessages(messages);
          setScrollToBottom(true);
        }
      });
    }
  }, [currentChatRoom, setListMessages]);

  useEffect(() => {
    const pendingMessage = Chat.getMessage();
    if (pendingMessage) {
      sendMessage(pendingMessage, "order");
    }
  });

  useEffect(() => {
    if (scrollToBottom) {
      scroller.scrollTo("bottom-scroll");
      setScrollToBottom(false);
    }
  }, [scrollToBottom, setScrollToBottom]);

  const onChangeMessage = (e: any) => setNewMessage(e.target.value);

  const onSendMessage = () => {
    sendMessage(newMessage, "message");
    setNewMessage("");
    setScrollToBottom(true);
    document.body.scrollTo(0, document.body.scrollHeight);
  };

  const onKeyInputMessageDown = (e: any) => {
    if (e.key === "Enter") {
      onSendMessage();
    }
  };

  return (
    <div className="chat-container">
      <div className="messages">
        {listMessages.map((message) => {
          const classPosition =
            message.idFrom === clientUid
              ? "message-right container"
              : "message-left container";

          const classStyle = message.type === "order" ? "order" : "";
          return (
            <div
              className={classnames(classPosition, classStyle)}
              key={moment(message.timestamp).toISOString()}
            >
              <p>{message.content}</p>
              <span className="timestamp">
                {moment(message.timestamp).format("MM-DD-YYYY LTS")}
              </span>
            </div>
          );
        })}
      </div>
      <Element name="bottom-scroll"></Element>
      <div className="input-chat">
        <InputText
          value={newMessage}
          onChange={onChangeMessage}
          onKeyDown={onKeyInputMessageDown}
        />
        <Button onClick={onSendMessage} icon="pi pi-comment" />
      </div>
    </div>
  );
};
