import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Mutation } from "react-apollo";

import { LOGIN_MUTATION } from "graphql/mutations/login";
import { Auth } from "utils/auth";
import { GQLAuthResponse, GQLLogin } from "models/auth";
import { ROLE_GUEST } from "config/constants";

import "./Login.scss";

export const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorClient, setErrorClient] = useState(false);

  if (Auth.isAuthenticated()) {
    window.location.href = "/";
  }

  const loggedIn = (login?: GQLLogin) => {
    if (login && login.jwt && login.user.role.name === ROLE_GUEST) {
      setErrorClient(false);
      Auth.authenticate(
        login.jwt,
        login.user.role.name,
        login.user.id || ""
      ).catch(() => {
        Auth.clear();
        setErrorClient(true);
      });
    } else {
      Auth.clear();
      setError(true);
    }
  };

  const onKeyInputDown = (mutation: any) => (e: any) => {
    if (e.key === "Enter" && email.length && password.length) {
      mutation();
    }
  };

  return (
    <div className="login-container">
      <h2>Welcome</h2>
      <Mutation
        mutation={LOGIN_MUTATION}
        variables={{ email: email.toLocaleLowerCase(), password }}
        onCompleted={({ login }: GQLAuthResponse) => loggedIn(login)}
        onError={() => setError(true)}
      >
        {(mutation) => (
          <div className="login-form">
            <div className="field">
              <label htmlFor="email">Email</label>
              <InputText
                id="email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                onKeyDown={onKeyInputDown(mutation)}
                placeholder="Enter your email"
              />
            </div>
            <div className="field">
              <label htmlFor="password">Password</label>
              <Password
                id="password"
                value={password}
                feedback={false}
                onChange={(e: any) => setPassword(e.target.value)}
                onKeyDown={onKeyInputDown(mutation)}
                placeholder="Enter your password"
              />
            </div>

            {error ? (
              <div className="error-msg">Error in your email or password</div>
            ) : null}
            {errorClient ? (
              <div className="error-msg">We couldn't find a Reservation</div>
            ) : null}

            <div className="button-container">
              <Button
                label="Login"
                className="btn-login"
                onClick={() => mutation()}
              />
            </div>
          </div>
        )}
      </Mutation>
    </div>
  );
};
