import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";

import { ACTIVE_PLACES_QUERY } from "graphql/queries/guests/activePlaces";
import { Auth } from "utils/auth";
import { GQLGuest } from "models/user";
import { ListPlaces } from "components/ListPlaces";

const ListPlacesContainer = () => {
  const userId = Auth.getUserId();
  return (
    <Query query={ACTIVE_PLACES_QUERY} userId={userId}>
      {({ data: { guests }, refetch }: QueryResponse<GQLGuest>) => {
        return <ListPlaces data={guests} refetch={refetch} />;
      }}
    </Query>
  );
};

export default ListPlacesContainer;
