import React, { Component } from "react";

import { AppSubmenu } from "components/AppSubMenu";

import "./AppMenu.scss";

interface AppMenuProps {
  model: any[];
  onMenuItemClick: any;
}

export class AppMenu extends Component<AppMenuProps> {
  static defaultProps = {
    model: null,
    onMenuItemClick: null,
  };

  render() {
    return (
      <div className="layout-menu-container">
        <AppSubmenu
          items={this.props.model}
          className="layout-menu"
          onMenuItemClick={this.props.onMenuItemClick}
          root={true}
        />
      </div>
    );
  }
}
