import gql from "graphql-tag";

export const ORDERS_QUERY = gql`
  query Orders($clientUid: String!, $guestId: ID!) {
    orders(
      where: { clientUid: $clientUid, guest: $guestId }
      sort: "lastUpdate:desc"
    ) {
      id
      status
      lastUpdate
      created
      type
      idType
    }

    services(where: { clientUid: $clientUid }) {
      id
      label
      description
      price
    }
  }
`;
