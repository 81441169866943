import React from "react";
import moment from "moment";
import { Mutation } from "react-apollo";

import { Button } from "primereact/button";
import { Growl } from "primereact/growl";

import { GQLService } from "models/services";
import { GQLRoom } from "models/room";
import { CREATE_ORDER_MUTATION } from "graphql/mutations/orders";
import { Auth } from "utils/auth";
import { Chat } from "utils/chat";
import { useHistory } from "react-router-dom";
import Api from "api";
import { RoomActions } from "components/RoomActions";

interface ServicesProps {
  data: GQLService[];
  room: GQLRoom;
  refetch: any;
}

export const Services: React.FC<ServicesProps> = ({ data, room, refetch }) => {
  const history = useHistory();
  const clientUid = Auth.getClientUid();
  const clientId = Auth.getClientId();
  const guestId = Auth.getGuestId();

  let growl;

  const orderCompleted = ({ order }) => {
    if (order.id) {
      if (growl) {
        growl.show({
          severity: "success",
          summary: "The order has been placed",
          detail: "Order submitted",
        });
        Api.sendNotification("orders");
      }
      refetch();
    } else {
      growl.show({
        severity: "error",
        summary: "Error trying to send the order",
        detail: "Error",
      });
    }
  };

  return (
    <div className="list-services">
      <h3>Services</h3>
      <Growl ref={(el) => (growl = el)} />
      {room && room.id ? (
        <RoomActions
          roomId={room.id}
          laundry={room.laundry || false}
          notDisturb={room.notDisturb || false}
        />
      ) : null}
      {data.map(
        ({ id, image, label, description, price, type }: GQLService) => (
          <div className="card-service" key={id}>
            {image ? (
              <div className="p-col-4">
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}${image.url}`}
                  alt={label}
                />
              </div>
            ) : null}
            <div>
              <h3>{label}</h3>
              <p>{description}</p>
              <p className="price">Price: {price}</p>
            </div>
            <div className="action-buttons">
              <Mutation
                mutation={CREATE_ORDER_MUTATION}
                variables={{
                  clientId,
                  clientUid: clientUid,
                  guestId,
                  created: moment().format(),
                  status: "pending",
                  type: "services",
                  typeId: id,
                }}
                onCompleted={({ createOrder }) => orderCompleted(createOrder)}
              >
                {(mutation: any) => (
                  <Button
                    label={type === "chat" ? "Chat" : "Order"}
                    icon={`pi ${
                      type === "chat" ? "pi-comments" : "pi-shopping-cart"
                    }`}
                    iconPos="left"
                    onClick={() => {
                      if (type !== "chat") {
                        mutation();
                      } else {
                        Chat.pushMessage(`Service: ${label}`);
                        history.push("/chat");
                      }
                    }}
                  />
                )}
              </Mutation>
            </div>
          </div>
        )
      )}
    </div>
  );
};
