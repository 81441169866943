import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { DocumentNode } from "graphql";

interface QueryProps {
  children: any;
  query: DocumentNode;
  id?: string;
  clientUid?: string;
  clientId?: string;
  userId?: string;
  guestId?: string;
  section?: string;
  date?: string;
}
const Query = ({
  children,
  query,
  id,
  clientUid,
  clientId,
  userId,
  guestId,
  section,
  date,
}: QueryProps) => {
  const { data, loading, error, refetch } = useQuery(query, {
    variables: { id, clientUid, clientId, section, userId, guestId, date },
    fetchPolicy: "no-cache",
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {JSON.stringify(error)}</p>;
  return children({ data, refetch });
};

export default Query;
