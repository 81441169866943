import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";

import { ORDERS_QUERY } from "graphql/queries/orders/orders";
import { Auth } from "utils/auth";
import { GQLService } from "models/services";
import { GQLOrder } from "models/orders";
import { Orders } from "components/Orders";

const OrdersContainer: React.FC = () => {
  const clientUid = Auth.getClientUid();
  const guestId = Auth.getGuestId();
  return (
    <Query query={ORDERS_QUERY} clientUid={clientUid} guestId={guestId}>
      {({
        data: { orders, services },
        refetch,
      }: QueryResponse<GQLOrder> & QueryResponse<GQLService>) => {
        return <Orders data={orders} services={services} refetch={refetch} />;
      }}
    </Query>
  );
};

export default OrdersContainer;
