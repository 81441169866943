import React from "react";

import MyAccountContainer from "containers/MyAccountContainer";

import "./MyAccount.scss";

export const MyAccountPage: React.FC = () => {
  return (
    <div className="my-account-container">
      <MyAccountContainer />
    </div>
  );
};
