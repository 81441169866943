import firebase from "firebase/app";
import "firebase/database";
import "firebase/messaging";

const config = {
  apiKey: "AIzaSyDvT_QR7lSBC3tcQ7nKTS9J7lLeGhi-dSw",
  authDomain: "klivr-hotel.firebaseapp.com",
  databaseURL: "https://klivr-hotel.firebaseio.com",
  projectId: "klivr-hotel",
  storageBucket: "klivr-hotel.appspot.com",
  messagingSenderId: "161057540742",
  appId: "1:161057540742:web:75a22a0a492935b326df07",
};

const initializedFirebaseApp = firebase.initializeApp(config);

if (firebase.messaging.isSupported()) {
  const messaging = initializedFirebaseApp.messaging();

  messaging.usePublicVapidKey(
    "BKO-o1bnpIOogYneroDOJrA7k-cWuZCh0fLXgt-h1srJoCrg4dvEyYfpqp5WB45BsdEuWVIsjvQECKKpBCWGLdI"
  );
}

export const initFCM = async () => {
  if (firebase.messaging.isSupported()) {
    try {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      console.log("user token:", token);

      return token;
    } catch (error) {
      if (error.code === "messaging/permission-blocked") {
        console.error("Please Unblock Notification Request Manually");
      } else {
        console.error("There was an error", error);
      }
    }
  }
};

export const fbDatabase = firebase.database();
export const supportedMessaging = firebase.messaging.isSupported();
export const fbMessaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;
