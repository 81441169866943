import React from "react";

import "./Services.scss";
import ServicesContainer from "containers/ServicesContainer";

export const ServicesPage = () => (
  <div className="services-container">
    <ServicesContainer />
  </div>
);
