import React from "react";

import ListPlacesContainer from "containers/ListPlacesContainer";

import "./ListPlaces.scss";
import { useHistory } from "react-router-dom";
import { Auth } from "utils/auth";

export const ListPlacesPage: React.FC = () => {
  const history = useHistory();

  if (Auth.getClientId()) {
    history.push("/");
  }

  return (
    <div className="list-places-container">
      <ListPlacesContainer />
    </div>
  );
};
