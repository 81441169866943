import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";

import { SECTION_DATA_QUERY } from "graphql/queries/sections/sectionData";
import { Section } from "components/Section";
import { SliderSection } from "components/SliderSection";
import { GQLDataSection } from "models/dataSections";
import { Auth } from "utils/auth";
import { GQLSection } from "models/sections";

interface SectionContainerProps {
  sectionName: string;
}

const SectionContainer: React.FC<SectionContainerProps> = ({ sectionName }) => {
  const clientUid = Auth.getClientUid();
  return (
    <Query
      query={SECTION_DATA_QUERY}
      section={sectionName}
      clientId={clientUid}
    >
      {({
        data: { dataSections, sections },
        refetch,
      }: QueryResponse<GQLDataSection> & QueryResponse<GQLSection>) => {
        return sections[0] && (sections[0] as GQLSection).slider ? (
          <SliderSection
            data={dataSections}
            refetch={refetch}
            sectionName={sectionName}
          />
        ) : (
          <Section
            data={dataSections}
            refetch={refetch}
            sectionName={sectionName}
          />
        );
      }}
    </Query>
  );
};

export default SectionContainer;
