import React from "react";
import { useHistory } from "react-router-dom";

import { GQLGuest } from "models/user";
import { Auth } from "utils/auth";
import Api from "api";

interface ListPlacesProps {
  data: GQLGuest[];
  refetch: any;
}

export const ListPlaces: React.FC<ListPlacesProps> = ({ data, refetch }) => {
  const history = useHistory();
  const enterPlace = (
    guestId: string,
    clientId: string,
    clientUid: string,
    chatRoom: string
  ) => {
    Auth.setClient(guestId, clientId, clientUid, chatRoom);
    history.push("/");
  };

  const checkIn = (
    guestId: string,
    clientId: string,
    clientUid: string,
    chatRoom: string
  ) => {
    Auth.setClient(guestId, clientId, clientUid, chatRoom);
    history.push("/checkIn");
  };

  const checkOut = async (guestId: string) => {
    Api.checkOut(guestId).then(() => {
      refetch();
    });
  };

  const enterButton = (
    guestId: string,
    clientId: string,
    clientUid: string,
    chatRoom: string
  ) => (
    <div
      className="buttonListAction"
      onClick={() => enterPlace(guestId, clientId, clientUid, chatRoom)}
    >
      Enter
    </div>
  );

  return (
    <ul className="list-places">
      {data.map((guest: GQLGuest) => (
        <li key={guest.id} className="place">
          {guest.client.branding && guest.client.branding.logo ? (
            <div className="logo">
              <img
                alt={guest.client.name}
                src={`${process.env.REACT_APP_BACKEND_URL}${guest.client.branding.logo.url}`}
              />
            </div>
          ) : null}
          <div className="name">{guest.client.name}</div>
          <div className="options">
            {guest.client.setting.checkIn ? (
              guest.checkedIn ? (
                <>
                  {enterButton(
                    guest.id,
                    guest.client.id,
                    guest.client.uid,
                    guest.chatRoom
                  )}
                  <div
                    className="buttonListAction"
                    onClick={() => checkOut(guest.id)}
                  >
                    Check Out
                  </div>
                </>
              ) : (
                <div
                  className="buttonListAction"
                  onClick={() =>
                    checkIn(
                      guest.id,
                      guest.client.id,
                      guest.client.uid,
                      guest.chatRoom
                    )
                  }
                >
                  Check In
                </div>
              )
            ) : (
              enterButton(
                guest.id,
                guest.client.id,
                guest.client.uid,
                guest.chatRoom
              )
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};
