import React from "react";
import { useHistory } from "react-router-dom";

import CheckInContainer from "containers/CheckInContainer";

import "./CheckIn.scss";
import { Auth } from "utils/auth";

export const CheckInPage: React.FC = () => {
  const history = useHistory();
  if (!Auth.getClientId()) {
    history.push("/list");
  }

  return (
    <div className="check-in-container">
      <CheckInContainer />
    </div>
  );
};
