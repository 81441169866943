import React from "react";

import "./Orders.scss";
import OrdersContainer from "containers/OrdersContainer";

export const OrdersPage = () => (
  <div className="order-containers">
    <OrdersContainer />
  </div>
);
