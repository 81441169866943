import React from "react";
import { useParams } from "react-router-dom";

import SectionContainer from "containers/SectionContainer";

import "./Section.scss";

export const SectionPage = () => {
  const { name } = useParams();

  return (
    <div className="section-container">
      <SectionContainer sectionName={name} />
    </div>
  );
};
