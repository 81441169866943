import Axios, { AxiosResponse } from "axios";
import { Auth } from "utils/auth";

class Api {
  static getAuthHeaders() {
    const token = Auth.getToken();
    return {
      authorization: token ? `Bearer ${token}` : "",
    };
  }

  static checkOut(guestId: string) {
    return new Promise((resolve, reject) => {
      Axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/guests/${guestId}`,
        data: {
          active: false,
        },
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static checkIn(guestId: string) {
    return new Promise((resolve, reject) => {
      Axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/guests/${guestId}`,
        data: {
          checkedIn: true,
        },
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static registerToken(token: string) {
    const guestId = Auth.getGuestId();
    return new Promise((resolve, reject) => {
      Axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/guests/${guestId}`,
        headers: {
          ...this.getAuthHeaders(),
        },
        data: {
          fcmToken: token,
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static sendNotification(section: string) {
    const clientId = Auth.getClientId();
    const clientUid = Auth.getClientUid();
    const guestId = Auth.getGuestId();

    return new Promise((resolve, reject) => {
      Axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/notifications`,
        headers: {
          ...this.getAuthHeaders(),
        },
        data: {
          client: clientId,
          clientUid,
          guest: guestId,
          section,
          origin: "guest",
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  static getNewNotifications() {
    const clientUid = Auth.getClientUid();
    const guestId = Auth.getGuestId();
    return new Promise((resolve, reject) => {
      Axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/notifications/?clientUid_eq=${clientUid}&status_eq=unread&origin=client&guest=${guestId}`,
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static markAsRead(notificationId: string) {
    return new Promise((resolve, reject) => {
      Axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/notifications/${notificationId}`,
        headers: {
          ...this.getAuthHeaders(),
        },
        data: {
          status: "read",
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}

export default Api;
