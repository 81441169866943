import gql from "graphql-tag";

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(input: { identifier: $email, password: $password }) {
      jwt
      user {
        id
        role {
          name
        }
        email
      }
    }
  }
`;
