import React from "react";

import Query from "components/Query";
import { QuerySingleResponse } from "models/query";

import { MY_ACCOUNT_QUERY } from "graphql/queries/users/myAccount";
import { MyAccount } from "components/MyAccount";
import { GQLUser } from "models/user";
import { Auth } from "utils/auth";

const MyAccountContainer: React.FC = () => {
  const userId = Auth.getUserId();
  return (
    <Query query={MY_ACCOUNT_QUERY} userId={userId}>
      {({ data: { user }, refetch }: QuerySingleResponse<GQLUser>) => {
        return <MyAccount data={user} refetch={refetch} />;
      }}
    </Query>
  );
};

export default MyAccountContainer;
