import React from "react";
import moment from "moment";

import Query from "components/Query";
import { QueryResponse } from "models/query";

import { SECTION_INFO_DATA_QUERY } from "graphql/queries/sections/sectionInfoData";
import { SectionInfo } from "components/SectionInfo";
import { GQLDataSection } from "models/dataSections";
import { Auth } from "utils/auth";
import { GQLGuest } from "models/user";
import { GQL_DATE_FORMAT } from "config/constants";

const SectionInfoContainer: React.FC = () => {
  const clientUid = Auth.getClientUid();
  const guestId = Auth.getGuestId();

  return (
    <Query
      query={SECTION_INFO_DATA_QUERY}
      section="Information"
      clientId={clientUid}
      guestId={guestId}
      date={moment().format(GQL_DATE_FORMAT)}
    >
      {({
        data: { dataSections, guests },
        refetch,
      }: QueryResponse<GQLDataSection> & QueryResponse<GQLGuest>) => {
        return (
          <SectionInfo
            data={dataSections}
            guest={guests[0]}
            refetch={refetch}
          />
        );
      }}
    </Query>
  );
};

export default SectionInfoContainer;
