import React from "react";
import classNames from "classnames";

import { MAIN_MENU_OPTIONS } from "config/menu";
import { AppMenu } from "components/AppMenu";

import "./AppSidebar.scss";

interface ISidebarProps {
  onSidebarClick: any;
  onMenuItemClick: any;
}

const logo = `${process.env.PUBLIC_URL}/images/mini-logo-white.png`;

export const AppSidebar = ({
  onSidebarClick,
  onMenuItemClick,
}: ISidebarProps) => (
  <div
    className={classNames("layout-sidebar", "layout-sidebar-dark")}
    onClick={onSidebarClick}
  >
    <div className="layout-logo">
      <img alt="Logo" src={logo} />
    </div>
    <AppMenu model={MAIN_MENU_OPTIONS} onMenuItemClick={onMenuItemClick} />
  </div>
);
