export const AUTH_TOKEN = "klivr-hotel-token";
export const STORAGE_CLIENT_ID = "klivr-hotel-client-id";
export const STORAGE_CLIENT_UID = "klivr-hotel-client-uid";
export const STORAGE_ROLE = "klivr-hotel-role";
export const STORAGE_USER = "klivr-hotel-user";
export const STORAGE_GUEST = "klivr-hotel-guest";
export const STORAGE_CHAT_ROOM = "klivr-hotel-chat-room";

export const ROLE_GUEST = "Guest";

export const DATE_FORMAT = "DD-MM-YYYY";
export const GQL_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm";
