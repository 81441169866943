import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { Auth } from "utils/auth";

export const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  const Component = component as any;

  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
