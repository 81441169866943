import React from "react";
import moment from "moment";
import { Mutation } from "react-apollo";

import { Growl } from "primereact/growl";
import { Button } from "primereact/button";

import { GQLOrder } from "models/orders";
import { DATE_TIME_FORMAT } from "config/constants";
import { CANCEL_ORDER_MUTATION } from "graphql/mutations/orders";
import { GQLService } from "models/services";
import { normalizeArray } from "utils/arrays";
import Api from "api";

interface OrdersProps {
  data: GQLOrder[];
  services: GQLService[];
  refetch: any;
}

export const Orders: React.FC<OrdersProps> = ({ data, services, refetch }) => {
  const normalizedServices = normalizeArray(services);

  let growl;

  refetch();

  const orderCanceled = ({ order }) => {
    if (order.id) {
      if (growl) {
        growl.show({
          severity: "success",
          summary: "The order has been canceled",
          detail: "Order submitted",
        });

        Api.sendNotification("orders");
      }
      refetch();
    } else {
      growl.show({
        severity: "error",
        summary: "Error trying to cancel the order",
        detail: "Error",
      });
    }
  };
  return (
    <>
      <h3>Orders</h3>
      <Growl ref={(el) => (growl = el)} />
      {data.map(({ id, lastUpdate, idType, status }: GQLOrder) => (
        <div className="card" key={id}>
          <div className="details">
            <h4>{normalizedServices[idType].label}</h4>
            <p className="status">{status}</p>
            <p className="date">
              {moment(lastUpdate).format(DATE_TIME_FORMAT)}
            </p>
          </div>
          {status !== "canceled" && status !== "completed" ? (
            <div className="action-button">
              <Mutation
                mutation={CANCEL_ORDER_MUTATION}
                variables={{
                  orderId: id,
                  lastUpdate: moment().format(),
                  status: "canceled",
                }}
                onCompleted={({ updateOrder }) => orderCanceled(updateOrder)}
              >
                {(mutation: any) => (
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    iconPos="left"
                    onClick={() => {
                      mutation();
                    }}
                  />
                )}
              </Mutation>
            </div>
          ) : null}
        </div>
      ))}
    </>
  );
};
