import React from "react";
import moment from "moment";

import { GQLDataSection } from "models/dataSections";

import { GQLGuest } from "models/user";
import { DATE_FORMAT } from "config/constants";
import { RoomActions } from "components/RoomActions";

interface SectionInfoProps {
  data: GQLDataSection[];
  guest: GQLGuest;
  refetch: any;
}

export const SectionInfo: React.FC<SectionInfoProps> = ({ data, guest }) => {
  const room = guest ? guest.room : null;

  return (
    <>
      {room && room.id ? (
        <div className="reservation">
          <h2>Your Reservation</h2>
          <div className="check">
            <div className="check-data">
              <span className="title">Checked in:</span>
              <span className="details">
                {(guest && moment(guest.checkIn).format(DATE_FORMAT)) || "-"}
              </span>
            </div>
            <div className="check-data">
              <span className="title">Checkout out:</span>
              <span className="details">
                {(guest && moment(guest.checkOut).format(DATE_FORMAT)) || "-"}
              </span>
            </div>
          </div>
          <RoomActions
            roomId={room.id}
            laundry={room.laundry || false}
            notDisturb={room.notDisturb || false}
          />
        </div>
      ) : null}
      {data.map(({ id, title, description }: GQLDataSection) => (
        <div className="section-data" key={id}>
          <span className="title">{title}</span>
          <span className="detail">{description}</span>
        </div>
      ))}
    </>
  );
};
