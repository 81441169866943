import React from "react";
import { useHistory } from "react-router-dom";

import HomeContainer from "containers/HomeContainer";

import "./Home.scss";
import { Auth } from "utils/auth";

export const HomePage: React.FC = () => {
  const history = useHistory();
  if (!Auth.getClientId()) {
    history.push("/list");
  }

  return (
    <div className="home-container">
      <HomeContainer />
    </div>
  );
};
