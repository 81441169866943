import gql from "graphql-tag";

export const MY_ACCOUNT_QUERY = gql`
  query MyAccount($userId: ID!) {
    user(id: $userId) {
      email
      username
      name
    }
  }
`;
