import React, { useEffect, useState } from "react";

import "./AppTopbar.scss";
import { Auth } from "utils/auth";
import Api from "api";

interface AppTopBarProps {
  onToggleMenu: any;
}

export const AppTopbar: React.FC<AppTopBarProps> = ({ onToggleMenu }) => {
  const [notifications, setNotifications] = useState([]);
  const [reading, setReading] = useState(false);

  useEffect(() => {
    const notificationsPolling = setInterval(async () => {
      if (!reading) {
        try {
          const newNotifications = (await Api.getNewNotifications()) as any[];
          setNotifications(newNotifications);
        } catch (error) {
          console.error("Notif: ", error);
          setNotifications([]);
        }
      }
    }, 5000);

    return () => clearInterval(notificationsPolling);
  }, [reading, setNotifications]);

  const readNotifications = () => {
    setReading(true);

    notifications.forEach((notification) => {
      Api.markAsRead(notification.id);
    });

    setReading(false);
  };

  return (
    <div className="layout-topbar">
      {Auth.isAuthenticated() ? (
        <button className="p-link layout-menu-button" onClick={onToggleMenu}>
          <img src="/icons/burger-menu.png" alt="menu" />
        </button>
      ) : null}
      <div className="layout-topbar-title">Klivr Club</div>
      {Auth.isAuthenticated() && Auth.getClientUid() ? (
        <div className="layout-topbar-icons">
          <button className="p-link" onClick={readNotifications}>
            <span className="layout-topbar-item-text">Notifications</span>
            <span className="layout-topbar-icon pi pi-bell" />
            <span className="layout-topbar-badge">{notifications.length}</span>
          </button>
        </div>
      ) : null}
    </div>
  );
};
