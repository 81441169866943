import React from "react";

import { GQLDataSection } from "models/dataSections";

import "./Section.scss";

interface SectionProps {
  data: GQLDataSection[];
  sectionName: string;
  refetch: any;
}

export const Section: React.FC<SectionProps> = ({ data, sectionName }) => {
  return (
    <div className="list">
      <h2>{sectionName}</h2>
      {data.map(
        ({ id, image, title, description, subDescription }: GQLDataSection) => (
          <div className="card" key={id}>
            {image ? (
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}${image.url}`}
                alt={title}
              />
            ) : null}
            <h3>{title}</h3>
            <p>{description}</p>
            {subDescription ? (
              <span className="sub-description">{subDescription}</span>
            ) : null}
          </div>
        )
      )}
    </div>
  );
};
