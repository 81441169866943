import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import SignaturePad from "react-signature-pad-wrapper";
import { Dialog } from "primereact/dialog";

import { GQLCheckInConfig } from "models/checkIn";
import { Button } from "primereact/button";

import "./CheckIn.scss";
import Api from "api";
import { Auth } from "utils/auth";

interface CheckInProps {
  data: GQLCheckInConfig[];
  refetch: any;
}

export const CheckIn: React.FC<CheckInProps> = ({ data, refetch }) => {
  const history = useHistory();
  const [values, setValues] = useState({});
  const [dates, setDates] = useState({});
  let signaturePad;
  // const [files, setFiles] = useState([]);
  // const [signatures, setSignatures] = useState([]);
  const [showSign, setShowSign] = useState(false);

  const rendersField = {
    text: (checkIn: GQLCheckInConfig) => (
      <div className="field-text">
        <h3>{checkIn.label}</h3>
        {checkIn.description ? <p>{checkIn.description}</p> : null}
      </div>
    ),
    value: (checkIn: GQLCheckInConfig) => (
      <div className="field-input">
        <label>{checkIn.label}</label>
        {checkIn.description ? <p>{checkIn.description}</p> : null}
        <InputText
          value={values[checkIn.name]}
          onChange={(e: any) =>
            setValues({
              ...values,
              [checkIn.name]: e.target.value,
            })
          }
        />
      </div>
    ),
    date: (checkIn: GQLCheckInConfig) => (
      <div className="field-date">
        <label>{checkIn.label}</label>
        {checkIn.description ? <p>{checkIn.description}</p> : null}
        <Calendar
          value={dates[checkIn.name]}
          dateFormat="dd-mm-yy"
          onChange={(e: any) =>
            setDates({
              ...values,
              [checkIn.name]: e.value,
            })
          }
        ></Calendar>
      </div>
    ),
    file: (checkIn: GQLCheckInConfig) => (
      <div className="field-file">
        <label>{checkIn.label}</label>
        <FileUpload url="./upload" mode="basic" auto={true} />
        {checkIn.description ? <p>{checkIn.description}</p> : null}
      </div>
    ),
    signature: (checkIn: GQLCheckInConfig) => (
      <div className="field-sign">
        <Button
          icon="pi pi-pencil"
          iconPos="left"
          label={checkIn.label}
          onClick={() => setShowSign(true)}
        />
        {checkIn.description ? <p>{checkIn.description}</p> : null}
      </div>
    ),
  };

  const addFooter = () => (
    <>
      <Button
        label="Clear"
        onClick={() => signaturePad && signaturePad.clear()}
      />
      <Button label="Save" onClick={() => setShowSign(false)} />
    </>
  );

  const checkIn = () => {
    Api.checkIn(Auth.getGuestId()).catch((err) => {
      console.log(err);
    });
    history.push("/");
  };

  return (
    <div className="content-form">
      <h3>Pre Check In</h3>
      <Dialog
        header={"Draw your signature"}
        visible={showSign}
        footer={addFooter()}
        className="sign-dialog"
        onHide={() => {
          setShowSign(false);
        }}
      >
        <SignaturePad
          options={{
            width: "300px",
            height: "400px",
            penColor: "rgb(0, 0, 0)",
          }}
          redrawOnResize={true}
          ref={(ref) => (signaturePad = ref)}
        />
      </Dialog>
      <ul className="form-check-in">
        {data.map((checkIn: GQLCheckInConfig) => (
          <li key={checkIn.id} className="field">
            {rendersField[checkIn.type](checkIn)}
          </li>
        ))}
        <li className="field actions">
          <Button label="Continue" onClick={checkIn} />
        </li>
      </ul>
    </div>
  );
};
