import React from "react";
import moment from "moment";

import Query from "components/Query";
import { QueryResponse } from "models/query";

import { SERVICES_QUERY } from "graphql/queries/services/services";
import { Auth } from "utils/auth";
import { Services } from "components/Services";
import { GQLService } from "models/services";
import { GQLGuest } from "models/user";
import { GQL_DATE_FORMAT } from "config/constants";

const ServicesContainer: React.FC = () => {
  const clientUid = Auth.getClientUid();
  const guestId = Auth.getGuestId();
  return (
    <Query
      query={SERVICES_QUERY}
      clientId={clientUid}
      guestId={guestId}
      date={moment().format(GQL_DATE_FORMAT)}
    >
      {({
        data: { services, guests },
        refetch,
      }: QueryResponse<GQLService> & QueryResponse<GQLGuest>) => {
        return (
          <Services
            data={services}
            room={guests[0] ? guests[0].room : null}
            refetch={refetch}
          />
        );
      }}
    </Query>
  );
};

export default ServicesContainer;
