import gql from "graphql-tag";

export const SERVICES_QUERY = gql`
  query Services($clientId: String!, $guestId: ID!, $date: Date) {
    services(where: { clientUid: $clientId }, sort: "order:asc") {
      id
      label
      image {
        url
      }
      description
      price
      type
    }

    guests(where: { id: $guestId, checkIn_lte: $date, checkOut_gt: $date }) {
      room {
        id
        notDisturb
        laundry
      }
    }
  }
`;
