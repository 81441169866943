const CHAT_MESSAGE = "klivr-client-app-chat-message";

export const Chat = {
  pushMessage: (message: string) => {
    localStorage.setItem(CHAT_MESSAGE, message);
  },
  getMessage: () => {
    const message = localStorage.getItem(CHAT_MESSAGE) || "";
    localStorage.removeItem(CHAT_MESSAGE);
    return message;
  },
};
