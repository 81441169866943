import React from "react";

import SectionInfoContainer from "containers/SectionInfoContainer";

import "./Information.scss";

export const InformationPage: React.FC = () => (
  <div className="info-container">
    <SectionInfoContainer />
  </div>
);
