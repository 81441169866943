import React from "react";
import { NavLink } from "react-router-dom";

import { GQLSection } from "models/sections";

interface HomeMenuProps {
  data: GQLSection[];
  refetch: any;
}

export const HomeMenu: React.FC<HomeMenuProps> = ({ data }) => (
  <>
    {data.map((section) => {
      const styleBox =
        section.name !== "Information"
          ? {
              backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${section.image.url})`,
            }
          : {};
      return (
        <NavLink
          activeClassName="active-route"
          to={
            section.name === "Information"
              ? "/information"
              : `/section/${section.name}`
          }
          key={section.id}
          style={styleBox}
          className={`btn-home ${section.name.toLowerCase()}`}
        >
          {section.name !== "Information" ? section.name : ""}
        </NavLink>
      );
    })}
    <NavLink
      activeClassName="active-route"
      to={"/services"}
      className="btn-home services"
    >
      Services
    </NavLink>
    {/* <div className="btn-home room-service">Room Service</div> */}
    <NavLink
      activeClassName="active-route"
      to={"/chat"}
      className="btn-home chat"
    >
      Chat
    </NavLink>
  </>
);
