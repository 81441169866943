import gql from "graphql-tag";

export const SECTION_DATA_QUERY = gql`
  query Sections($section: String!, $clientId: ID!) {
    dataSections(
      where: { clientUid: $clientId, section: { name: $section } }
      sort: "order:asc"
    ) {
      id
      title
      description
      subDescription
      image {
        url
      }
    }

    sections(where: { name: $section }, limit: 1) {
      id
      slider
    }
  }
`;
