import gql from "graphql-tag";

export const SECTION_INFO_DATA_QUERY = gql`
  query Sections(
    $section: String!
    $clientId: ID!
    $guestId: ID!
    $date: Date
  ) {
    dataSections(
      where: { clientUid: $clientId, section: { name: $section } }
      sort: "order:asc"
    ) {
      id
      title
      description
      subDescription
      image {
        url
      }
    }

    guests(where: { id: $guestId, checkIn_lte: $date, checkOut_gt: $date }) {
      checkIn
      checkOut
      room {
        id
        notDisturb
        laundry
      }
    }
  }
`;
