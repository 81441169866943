import React from "react";
import { Carousel } from "react-responsive-carousel";

import { GQLDataSection } from "models/dataSections";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import "./SliderSection.scss";

interface SliderSectionProps {
  data: GQLDataSection[];
  sectionName: string;
  refetch: any;
}

export const SliderSection: React.FC<SliderSectionProps> = ({
  data,
  sectionName,
}) => {
  return (
    <div className="slider-container">
      <h2>{sectionName}</h2>
      <Carousel
        showArrows={false}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        stopOnHover={false}
        swipeable={true}
      >
        {data.map(
          ({
            id,
            image,
            title,
            description,
            subDescription,
          }: GQLDataSection) => (
            <div className="slider-card" key={id}>
              {image ? (
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}${image.url}`}
                  alt={title}
                />
              ) : null}
              <h4>{title}</h4>
              <p>{description}</p>
              {subDescription ? (
                <span className="price">{subDescription}</span>
              ) : null}
            </div>
          )
        )}
      </Carousel>
    </div>
  );
};
