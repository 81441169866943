import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";

import { CHECK_IN_CONFIG_QUERY } from "graphql/queries/checkIn/checkInConfig";
import { CheckIn } from "components/CheckIn";
import { GQLCheckInConfig } from "models/checkIn";
import { Auth } from "utils/auth";

const CheckInContainer: React.FC = () => {
  const clientUid = Auth.getClientUid();
  return (
    <Query query={CHECK_IN_CONFIG_QUERY} clientId={clientUid}>
      {({
        data: { checkInConfigs },
        refetch,
      }: QueryResponse<GQLCheckInConfig>) => {
        return <CheckIn data={checkInConfigs} refetch={refetch} />;
      }}
    </Query>
  );
};

export default CheckInContainer;
