import gql from "graphql-tag";

export const UPDATE_ROOM_LAUNDRY_MUTATION = gql`
  mutation UpdateLaundry($roomId: ID!, $laundry: Boolean) {
    updateRoom(input: { where: { id: $roomId }, data: { laundry: $laundry } }) {
      room {
        id
      }
    }
  }
`;

export const UPDATE_ROOM_NOT_DISTURB_MUTATION = gql`
  mutation UpdateNotDisturb($roomId: ID!, $notDisturb: Boolean) {
    updateRoom(
      input: { where: { id: $roomId }, data: { notDisturb: $notDisturb } }
    ) {
      room {
        id
      }
    }
  }
`;
