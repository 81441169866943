import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { Growl } from "primereact/growl";
import { InputSwitch } from "primereact/inputswitch";

import {
  UPDATE_ROOM_LAUNDRY_MUTATION,
  UPDATE_ROOM_NOT_DISTURB_MUTATION,
} from "graphql/mutations/rooms";

interface RoomActionsProps {
  laundry: boolean;
  notDisturb: boolean;
  roomId: string;
}

export const RoomActions: React.FC<RoomActionsProps> = ({
  roomId,
  laundry: originalLaundry,
  notDisturb: originalNotDisturb,
}: RoomActionsProps) => {
  const [laundry, setLaundry] = useState(originalLaundry);
  const [notDisturb, setNotDisturb] = useState(originalNotDisturb);
  const roomUpdated = ({ room }) => {
    if (!room.id) {
      growl.show({
        severity: "error",
        summary: "Error updating the info",
        detail: "Error",
      });
    }
  };

  const [mutationLaundry] = useMutation(UPDATE_ROOM_LAUNDRY_MUTATION, {
    onError: () => roomUpdated({ room: {} }),
    onCompleted: ({ updateRoom }) => roomUpdated(updateRoom),
  });

  const [mutationNotDisturb] = useMutation(UPDATE_ROOM_NOT_DISTURB_MUTATION, {
    onError: () => roomUpdated({ room: {} }),
    onCompleted: ({ updateRoom }) => roomUpdated(updateRoom),
  });

  let growl;

  return (
    <div className="room-actions">
      <Growl ref={(el) => (growl = el)} />
      <div className="action">
        <label>Do not disturb</label>
        <InputSwitch
          checked={notDisturb}
          onChange={(e) => {
            mutationNotDisturb({
              variables: {
                roomId,
                notDisturb: e.value,
              },
            });
            setNotDisturb(e.value);
          }}
        />
      </div>
      <div className="action">
        <label>Laundry</label>
        <InputSwitch
          checked={laundry}
          onChange={(e) => {
            mutationLaundry({
              variables: {
                laundry: e.value,
                roomId,
              },
            });
            setLaundry(e.value);
          }}
        />
      </div>
    </div>
  );
};
