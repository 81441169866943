import { Auth } from "utils/auth";

interface IMenuOption {
  key: string;
  label: string;
  icon: string;
  badge?: number;
  isVisible?: () => boolean;
  command?: () => any;
  to?: string;
  items?: IMenuOption[];
}

export type IMenu = IMenuOption[];

export const MAIN_MENU_OPTIONS: IMenu = [
  {
    key: "1",
    label: "Main Menu",
    icon: "pi pi-fw pi-home",
    to: "/",
  },
  {
    key: "1.1",
    label: "Orders",
    icon: "pi pi-fw pi-tag",
    to: "/orders",
  },
  {
    key: "2",
    label: "Account",
    icon: "pi pi-fw pi-user",
    to: "/my-account",
  },
  {
    key: "4",
    label: "Close Place",
    icon: "pi pi-fw pi-sign-out",
    to: "/",
    isVisible: () => !!Auth.getGuestId(),
    command: () => {
      Auth.removeClient();
    },
  },
  {
    key: "5",
    label: "Logout",
    icon: "pi pi-fw pi-sign-out",
    to: "/",
    command: () => {
      Auth.clear();
    },
  },
];
