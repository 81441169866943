import gql from "graphql-tag";

export const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder(
    $created: DateTime
    $clientId: ID!
    $guestId: ID!
    $clientUid: String
    $typeId: String
    $status: ENUM_ORDERS_STATUS
    $type: ENUM_ORDERS_TYPE
  ) {
    createOrder(
      input: {
        data: {
          created: $created
          lastUpdate: $created
          client: $clientId
          clientUid: $clientUid
          idType: $typeId
          type: $type
          status: $status
          guest: $guestId
        }
      }
    ) {
      order {
        id
      }
    }
  }
`;

export const CANCEL_ORDER_MUTATION = gql`
  mutation CancelOrder(
    $orderId: ID!
    $lastUpdate: DateTime
    $status: ENUM_ORDERS_STATUS
  ) {
    updateOrder(
      input: {
        where: { id: $orderId }
        data: { lastUpdate: $lastUpdate, status: $status }
      }
    ) {
      order {
        id
      }
    }
  }
`;
