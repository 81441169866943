import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";
import classnames from "classnames";

import { Growl } from "primereact/growl";

import { HomePage } from "pages/Home";
import { ListPlacesPage } from "pages/ListPlaces";
import { OrdersPage } from "pages/Orders";
import { LoginPage } from "pages/Login";
import { InformationPage } from "pages/Information";
import { SectionPage } from "pages/Section";
import { CheckInPage } from "pages/CheckIn";
import { ServicesPage } from "pages/Services";
import { ChatPage } from "pages/Chat";
import { MyAccountPage } from "pages/MyAccount";

import { AppTopbar } from "components/AppTopbar";
import { AppSidebar } from "components/AppSidebar";
import { PrivateRoute } from "components/PrivateRoute";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { Auth } from "utils/auth";
import { initFCM, fbMessaging, supportedMessaging } from "config/firebase";
import Api from "api";

import "scss/layout.scss";
import "./App.scss";

interface AppState {
  layoutMode: string;
  staticMenuInactive: boolean;
  mobileMenuActive: boolean;
  isPlace: boolean;
  token: string;
}

class App extends Component<any, AppState> {
  private menuClick: boolean;
  private growl;

  constructor(props: any) {
    super(props);

    this.menuClick = false;

    this.state = {
      layoutMode: "static",
      staticMenuInactive: false,
      mobileMenuActive: false,
      token: "",
      isPlace: !!Auth.getClientUid(),
    };
  }

  async componentDidMount() {
    if (supportedMessaging) {
      if (this.state.isPlace && !this.state.token) {
        try {
          const token = await initFCM();
          console.log(token);
          Api.registerToken(token);
          this.setState({
            ...this.state,
            token,
          });
        } catch (err) {
          console.log(err);
        }
      }

      fbMessaging.onMessage((payload) => {
        this.growl.show({
          severity: "success",
          summary: payload.notification.title,
          detail: payload.notification.body,
          onClick: () =>
            this.props.history.push(`/${payload.notification.section}`),
        });
        Api.markAsRead(payload.notification.id);
      });
    }
  }

  onWrapperClick = () => {
    if (!this.menuClick) {
      this.setState({
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  };

  onToggleMenu = (event: any) => {
    this.menuClick = true;

    this.setState({
      mobileMenuActive: !this.state.mobileMenuActive,
    });

    event.preventDefault();
  };

  onSidebarClick = () => {
    this.menuClick = true;
  };

  onMenuItemClick = (event: any) => {
    if (!event.item.items) {
      this.setState({
        mobileMenuActive: false,
      });
    }
  };

  addClass(element: any, className: string) {
    if (element.classList) {
      element.classList.add(className);
    } else {
      element.className += " " + className;
    }
  }

  removeClass(element: any, className: string) {
    if (element.classList) {
      element.classList.remove(className);
    } else {
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive) {
      this.addClass(document.body, "body-overflow-hidden");
    } else {
      this.removeClass(document.body, "body-overflow-hidden");
    }
  }

  render() {
    const isAuthenticated = Auth.isAuthenticated();
    const isChat = this.props.location.pathname === "/chat";

    if (isChat) {
      this.addClass(document.body, "body-chat-hidden");
    } else {
      this.removeClass(document.body, "body-chat-hidden");
    }

    return (
      <div
        className={classnames("layout-wrapper", {
          "layout-mobile-sidebar-active": this.state.mobileMenuActive,
        })}
        onClick={this.onWrapperClick}
      >
        {isAuthenticated ? (
          <>
            <Growl ref={(el) => (this.growl = el)} />
            <AppTopbar onToggleMenu={this.onToggleMenu} />

            <AppSidebar
              onSidebarClick={this.onSidebarClick}
              onMenuItemClick={this.onMenuItemClick}
            />
          </>
        ) : null}

        <div
          className={classnames("layout-main", {
            "no-bottom-logo": isChat,
          })}
        >
          <Route path="/login" exact component={LoginPage} />
          <PrivateRoute path="/" exact component={HomePage} />
          <PrivateRoute path="/orders" exact component={OrdersPage} />
          <PrivateRoute path="/list" exact component={ListPlacesPage} />
          <PrivateRoute path="/checkIn" component={CheckInPage} />
          <PrivateRoute path="/section/:name" component={SectionPage} />
          <PrivateRoute path="/services" component={ServicesPage} />
          <PrivateRoute path="/information" component={InformationPage} />
          <PrivateRoute path="/chat" component={ChatPage} />
          <PrivateRoute path="/my-account" component={MyAccountPage} />
          {!isChat ? (
            <div className="signature">
              <img
                src={`${process.env.PUBLIC_URL}/images/mini-logo.png`}
                alt="Klivr Club"
              />
              <p>powered by Klivr</p>
            </div>
          ) : null}
        </div>
        <div className="layout-mask"></div>
      </div>
    );
  }
}

export default withRouter(App);
