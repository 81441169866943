import gql from "graphql-tag";

export const SECTIONS_QUERY = gql`
  query Sections($clientId: String!) {
    sections(
      where: { clientUid: $clientId, visible: true }
      sort: "order:asc"
      limit: 4
    ) {
      id
      name
      image {
        url
      }
      visible
      order
      slider
    }
  }
`;
