import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";

import { SECTIONS_QUERY } from "graphql/queries/sections/sections";
import { Auth } from "utils/auth";
import { HomeMenu } from "components/HomeMenu";
import { GQLSection } from "models/sections";

const HomeContainer = () => {
  const clientUid = Auth.getClientUid();
  return (
    <Query query={SECTIONS_QUERY} clientId={clientUid}>
      {({ data: { sections }, refetch }: QueryResponse<GQLSection>) => {
        return <HomeMenu data={sections} refetch={refetch} />;
      }}
    </Query>
  );
};

export default HomeContainer;
