import gql from "graphql-tag";

export const CHECK_IN_CONFIG_QUERY = gql`
  query checkInConfig($clientId: ID!) {
    checkInConfigs(where: { clientUid: $clientId }, sort: "order:asc") {
      id
      type
      name
      label
      description
      required
    }
  }
`;
